<template>
  <div class="container>">
    <div class="columns no-margin pencapaian">
      <div class="column no-padding">
        <div class="card">
          <header class="card-header has-background-primary">
            <div @click.stop="$router.go(-1)" class="card-header-icon">
              <b-icon class="has-text-light" size="is-small" icon="arrow-left" />
            </div>
            <p class="card-header-title has-text-light">PENCAPAIAN MAHASISWA</p>
          </header>
          <div class="card-content events-card">
            <div class="columns">
              <div class="column is-full">
                <div class="level">
                  <div class="level-item has-text-centered">
                    <img class="img-profil" :src="getWebpPath(anggota.foto)" />
                  </div>
                </div>
              </div>
            </div>
            <div class="columns is-mobile">
              <div class="column detail has-text-weight-semibold is-4">Nama</div>
              <div class="column detail has-text-weight-semibold is-1">:</div>
              <div class="column detail has-text-left">{{ anggota.nama }}</div>
            </div>
            <div class="columns is-mobile">
              <div class="column detail has-text-weight-semibold is-4">Angkatan</div>
              <div class="column detail has-text-weight-semibold is-1">:</div>
              <div class="column detail has-text-left">{{ anggota.angkatan }}</div>
            </div>
            <b-loading :is-full-page="false" :active.sync="loading" />
          </div>
        </div>
      </div>
    </div>

    <template v-if="isLoaded">
      <div class="columns no-margin pencapaian">
        <div class="column no-padding">
          <div class="card kelompok">
            <header class="card-header has-background-primary">
              <p class="card-header-title has-text-light">ROTASI</p>
            </header>
            <b-table striped hoverable :data="anggota.stasepspd_set" :mobile-cards="false">
              <b-table-column field="matakuliah" label="Mata Kuliah" v-slot="props">
                {{ props.row.stase.mata_kuliah }}
                <br />
                <small>{{ props.row.stase.mulai }} s/d {{ props.row.stase.hingga }}</small>
              </b-table-column>
              <!-- <b-table-column
                field="koordinator"
                label="Koordinator"
                v-slot="props"
              >{{ props.row.stase.preceptor }}</b-table-column> -->
              <b-table-column
                v-if="!isPSPD"
                field="final"
                label="Final"
                v-slot="props"
              >{{ formatNilai(props.row.nilai) }}</b-table-column>
              <b-table-column
                v-if="!isPSPD"
                field="mutu"
                label="Mutu"
                v-slot="props"
              >{{ props.row.predikat }}</b-table-column>
              <b-table-column field="lulus" label="Pass/Fail" v-slot="props">
                <hasil-mark :hasil="props.row.lulus"></hasil-mark>
              </b-table-column>

              <template slot="empty">
                <div class="content has-text-grey has-text-centered">
                  <p>Tidak ada data.</p>
                </div>
              </template>
            </b-table>
            <!-- <b-loading v-if="kelompokId === penc_pelayanan.kelompok_id" :is-full-page="false" :active.sync="loading" /> -->
          </div>
        </div>
      </div>
      <template v-if="anggota.pencapaian.length == 1">
        <div class="columns no-margin pencapaian">
          <div class="column no-padding">
            <div class="card">
              <header class="card-header">
                <p class="card-header-title has-background-primary has-text-light">Ujian</p>
              </header>
              <b-table striped hoverable :data="anggota.pencapaian[0].ujian_list" :mobile-cards="false">
                <!-- <b-table-column field="no" label="No">{{ props.index + 1 }}</b-table-column> -->
                <b-table-column
                  field="tanggal_maju"
                  label="Tanggal Maju"
                  v-slot="props"
                >{{ props.row.tanggal_ujian }}</b-table-column>
                <b-table-column
                  field="jenis"
                  label="Jenis"
                  v-slot="props"
                >{{ props.row.jenis__nama }}</b-table-column>
                <b-table-column field="judul" label="Judul" v-slot="props">{{ props.row.judul }}</b-table-column>
                <!-- <b-table-column field="verifikasi" label="Verifikasi" v-slot="props">
                  <hasil-mark :hasil="props.row.verifikasi"></hasil-mark>
                </b-table-column> -->
                <b-table-column
                  v-if="!isPSPD"
                  field="nilai"
                  label="Nilai"
                  v-slot="props"
                >{{ formatNilai(props.row.nilai) }}</b-table-column>
                <b-table-column field="hasil" label="Hasil" v-slot="props">
                  <hasil-mark :hasil="props.row.hasil"></hasil-mark>
                </b-table-column>

                <template slot="empty">
                  <div class="content has-text-grey has-text-centered">
                    <p>Tidak ada data.</p>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>

        <div class="columns no-margin pencapaian">
          <div class="column no-padding">
            <div class="card">
              <header class="card-header">
                <p class="card-header-title has-background-primary has-text-light">Materi</p>
              </header>
              <b-table
                bordered
                striped
                hoverable
                :loading="loading"
                :data="anggota.pencapaian[0].kompetensi_list"
                :mobile-cards="false"
              >
                <b-table-column label="No" v-slot="props">{{ props.index + 1 }}</b-table-column>
                <b-table-column
                  field="kuantitatif"
                  label="Kuantitatif"
                  v-slot="props"
                >{{ props.row.kompetensi__kuantitatif }}</b-table-column>
                <b-table-column
                  field="pencapaian"
                  label="Pencapaian"
                  centered
                  v-slot="props"
                >{{ props.row.pencapaian }}/<template v-if="props.row.target !== null">{{ props.row.target }}</template><template v-else>-</template></b-table-column>

                <template slot="empty">
                  <div class="content has-text-grey has-text-centered">
                    <p>Tidak ada data.</p>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </template>
      <b-collapse
        v-else
        class="card"
        v-for="(pencapaian, pencapaianIdx) of anggota.pencapaian"
        :key="pencapaianIdx"
        :open="isOpen == pencapaianIdx"
        @open="isOpen = pencapaianIdx"
      >
        <div
          slot="trigger"
          slot-scope="props"
          class="card-header has-background-primary bg-active"
          role="button"
        >
          <p class="card-header-title has-text-light">{{ pencapaian.matkul }}</p>
          <a class="card-header-icon has-text-light">
            <b-icon :icon="props.open ? 'chevron-down' : 'chevron-right'" size="is-small"></b-icon>
          </a>
        </div>
        <div class="card-content bg-active">
          <div class="content">
            <div class="columns no-margin pencapaian">
              <div class="column no-padding">
                <div class="card">
                  <header class="card-header">
                    <p class="card-header-title has-background-primary has-text-light">Ujian</p>
                  </header>
                  <b-table striped hoverable :data="pencapaian.ujian_list" :mobile-cards="false">
                    <!-- <b-table-column field="no" label="No">{{ props.index + 1 }}</b-table-column> -->
                    <b-table-column
                      field="tanggal_maju"
                      label="Tanggal Maju"
                      v-slot="props"
                    >{{ props.row.tanggal_ujian }}</b-table-column>
                    <b-table-column
                      field="jenis"
                      label="Jenis"
                      v-slot="props"
                    >{{ props.row.jenis__nama }}</b-table-column>
                    <b-table-column field="judul" label="Judul" v-slot="props">{{ props.row.judul }}</b-table-column>
                    <!-- <b-table-column field="verifikasi" label="Verifikasi" v-slot="props">
                      <hasil-mark :hasil="props.row.verifikasi"></hasil-mark>
                    </b-table-column> -->
                    <b-table-column
                      v-if="!isPSPD"
                      field="nilai"
                      label="Nilai"
                      v-slot="props"
                    >{{ formatNilai(props.row.nilai) }}</b-table-column>
                    <b-table-column field="hasil" label="Hasil" v-slot="props">
                      <hasil-mark :hasil="props.row.hasil"></hasil-mark>
                    </b-table-column>

                    <template slot="empty">
                      <div class="content has-text-grey has-text-centered">
                        <p>Tidak ada data.</p>
                      </div>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>

            <div class="columns no-margin pencapaian">
              <div class="column no-padding">
                <div class="card">
                  <header class="card-header">
                    <p class="card-header-title has-background-primary has-text-light">Materi</p>
                  </header>
                  <b-table
                    bordered
                    striped
                    hoverable
                    :loading="loading"
                    :data="pencapaian.kompetensi_list"
                    :mobile-cards="false"
                  >
                    <b-table-column label="No" v-slot="props">{{ props.index + 1 }}</b-table-column>
                    <b-table-column
                      field="kuantitatif"
                      label="Kuantitatif"
                      v-slot="props"
                    >{{ props.row.kompetensi__kuantitatif }}</b-table-column>
                    <b-table-column
                      field="pencapaian"
                      label="Pencapaian"
                      centered
                      v-slot="props"
                    >{{ props.row.pencapaian }}/<template v-if="props.row.target !== null">{{ props.row.target }}</template><template v-else>-</template></b-table-column>

                    <template slot="empty">
                      <div class="content has-text-grey has-text-centered">
                        <p>Tidak ada data.</p>
                      </div>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
    </template>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import fetchCreatedMixin from "@/apps/core/mixins/fetchCreatedMixin";
import onlineCheckMixin from "@/apps/core/mixins/onlineCheckMixin";
import PencapaianPSPD from "../models/aktPencDetail.js";
import { webpPath } from "@/apps/core/modules/utils.js";

const hasilMap = {
  Lulus: { class: "has-text-success", icon: "check" },
  "Tidak Lulus": { class: "has-text-danger", icon: "times" },
  Belum: { class: "has-text-info", icon: "minus" },
};

export default {
  name: "PencapaianPSPDDetail",
  data() {
    this.pencapaianMdl = new PencapaianPSPD();
    this.objectMdl = this.pencapaianMdl;
    let obv = this.pencapaianMdl.getObservables();
    obv.stase_belum_map = {};
    obv.isOpen = 0;
    return obv;
  },
  computed: {
    ...mapGetters("accounts", ["isPSPD"]),
  },
  components: {
    HasilMark: () => import("@/apps/core/components/HasilMark.vue"),
  },
  methods: {
    fetchData() {
      this.pencapaianMdl.load(this.$route.params.id);
    },
    getWebpPath(url) {
      return webpPath(url);
    },
    hasilClass(hasil) {
      return hasilMap[hasil].class;
    },
    hasilIcon(hasil) {
      return hasilMap[hasil].icon;
    },
    savePencapaian() {
      this.pencapaianMdl.update(this.fetchData);
    },
    formatNilai(nilai) {
      if (nilai == null) return "";
      const nilaiStr = nilai.toString();
      return nilaiStr.length > 2
        ? nilai.toString().replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, "$1")
        : nilai;
    },
  },
  mixins: [onlineCheckMixin, fetchCreatedMixin],
};
</script>

<style scoped lang="scss">
@media screen and (max-width: 768px) {
  .card-content {
    font-size: 0.9rem !important;
    padding: 0.5rem;
  }

  .columns {
    margin-right: unset !important;
  }
}

::v-deep .is-grouped-multiline {
  margin-bottom: unset !important;
}

.img-profil {
  width: 168px;
  height: 168px;
  object-fit: contain;
}

.kelompok {
  position: relative;
}

.pencapaian {
  padding-bottom: 1.5rem;
}
</style>