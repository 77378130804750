import axios from "axios";
import { ToastProgrammatic as Toast } from "buefy";
import APP_CONFIG from "@/apps/core/modules/config.js";
import ModelBase from "@/apps/core/models/modelBase.js";

const modelName = "anggota";
const modelPath = "/anggota/mahasiswa/";

class PencapaianPSPD extends ModelBase {
  constructor() {
    let model = {
      id: null,
      nama: "",
      angkatan: "",
      can_update: false,
      foto: `${APP_CONFIG.baseS3Url}/ppdh/img/profil.png`,
      stasepspd_set: [],
      pencapaian: []
    }
    super(modelName, model, [], [], modelPath);
  }

  getUpdateUrl() {
    return `${APP_CONFIG.baseAPIURL}/stase/pencapaian/${this.observables.anggota.id}/`;
  }

  getLoadData(data) {
    let anggota = super.getLoadData(data);
    for (let stase of anggota.stasepspd_set) {
      if (stase.lulus == 'Belum') {
      this.observables.stase_belum_map[stase.id] = stase;
      }
    }
    if (!anggota.foto) {
      anggota.foto = `${APP_CONFIG.baseS3Url}/ppdh/img/profil.png`;
    }
    return anggota;
  }

  getPayload() {
    let data = {data_set: {}};
    let stasepspd_set = this.observables.anggota.stasepspd_set;
    let editableSet = stasepspd_set.filter(d => d.can_update);
    for (let stase of editableSet) {
      data.data_set[stase.id] = {lulus: stase.lulus};
    }
    return data;
  }

  update(onSaved) {
    let updateURL = this.getUpdateUrl();
    const data = this.getPayload();
    this.observables.loading = true;
    axios.patch(updateURL, data)
      .then(() => {
        this.validity.edited = false;
        Toast.open("Data berhasil disimpan.");
        if (onSaved) {
          onSaved();
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {  // Perlu handle 403 juga
          Toast.open("Data gagal disimpan.");
        }
      })
      .finally(() => {
        this.observables.loading = false;
      });
  }
}

export default PencapaianPSPD;